.text-blink-danger {
  animation: color-change-danger 1s infinite;
}

@keyframes color-change-danger {
  0% {
    color: hsl(0, 92%, 60%);
  }

  50% {
    color: hsl(0, 92%, 23%);
  }

  100% {
    color: hsl(0, 92%, 60%);
  }
}
